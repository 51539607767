import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MOCK_PRODUCTS } from "../products/MockProducts";

function Header(props){
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const desktopMenuRef = useRef(null);
    const mobileMenuRef = useRef(null);
    const mobileMenuBackgroundRef = useRef(null);
    const desktopButtonRef = useRef(null);
    const mobileButtonRef = useRef(null);
    let {activeNav, activeProd} = props;
    const linkDest = "/produktas/"

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isDesktopMenuClicked = desktopMenuRef.current && desktopMenuRef.current.contains(event.target);
            const isDesktopButtonClicked = desktopButtonRef.current && desktopButtonRef.current.contains(event.target);
            const isMobileMenuClicked = mobileMenuRef.current && mobileMenuRef.current.contains(event.target);
            const isMobileButtonClicked = mobileButtonRef.current && mobileButtonRef.current.contains(event.target);
            const isMobileMenuBackgroundClicked = mobileMenuBackgroundRef.current && mobileMenuBackgroundRef.current.contains(event.target);

            if (!(isDesktopMenuClicked || isDesktopButtonClicked || isMobileMenuClicked || isMobileButtonClicked || isMobileMenuBackgroundClicked)) {
                setOpen(false);
                console.log("Clicked outside of menu and button");
                document.body.style.overflowY = 'visible';
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleContactsClick = () => {
        if (location.pathname === "/apiemus") {
            const element = document.getElementById("kolektyvo-section")
            if(element){
                element.scrollIntoView({behavior: "smooth"})
            }
        } else {
            const element = document.getElementById("susisiekimo-section")
            if(element){
                element.scrollIntoView({behavior: "smooth"})
            }
        }
       
    }

    const handleResize = () => {
        if (window.innerWidth > 800) {
            document.body.style.overflowY = 'visible';
        }
        // else if (open === true){
        //     document.body.style.overflowY = 'hidden';
        // }
    }

    const handleOpen = () => {
        if(window.innerWidth <= 800 && !open){
            document.body.style.overflowY = 'hidden';
        }else{
            document.body.style.overflowY = 'visible';
        }
        setOpen(!open)
    }

    const handleLinkClick = (id) => {

        setOpen(false);
        
        document.body.style.overflowY = 'visible';

        navigate(linkDest+id)
    }

    const handleLanguageClick = (lang) => {
        
        if(lang === "en"){
            window.location.replace("https://www.ustmachining.eu"+window.location.pathname)
        }
    }


    return(
        <nav className="headerDiv">
            <div className="headerTop">
                <a className="logoDiv" href="/">
                    <img src="../assets/Ustakles.svg" alt="Ukmergės staklės"></img>
                </a>
                <div className="languageDiv">
                    <div className="navActive">Lt</div>
                    <div onClick={() => handleLanguageClick("en")}>En</div>
                </div>
            </div>
            <div className="headerBottom">
                <div className="headerButtonsDiv">
                    <div className={activeNav === 1 ? "buttonDiv navActive" : "buttonDiv"}    ref={desktopButtonRef} onClick={() => {
            if (location.pathname === "/apiemus" || location.pathname === "/es") {
                // If the current route is "/apiemus", perform the desired action
                // Here, I'm redirecting to the default route "/"
                
                setOpen(false);
                navigate("/");
            } else {
                // Otherwise, toggle the open state as usual
                handleOpen();
                
            }
        }}>Gaminiai
                    {open ? 
                        <i className={activeNav === 1 ? "arrow up navActive" : "arrow up"}></i> : 
                        <i className={activeNav === 1 ? "arrow down navActive" : "arrow down"}></i>
                    }
                    </div>

                    <div className={activeNav === 2 ? "buttonDiv navActive" : "buttonDiv"} onClick={() => {navigate("/apiemus")}}>Apie mus</div>
                    <div className="buttonDiv" onClick={() => {handleContactsClick()}}>Kontaktai</div>
                    <div className={activeNav === 4 ? "buttonDiv navActive" : "buttonDiv"} onClick={() => {navigate("/es")}}>ES projektai</div>
                </div>
                <div className="Mobile" ref={mobileButtonRef}>
                    <img src="../assets/Meniu.svg" alt="Meniu" onClick={() => handleOpen()}></img>
                    <div className="mobileESbutton">
                    <div className={activeNav === 4 ? "buttonDiv navActive" : "buttonDiv"} onClick={() => {navigate("/es")}}>ES projektai</div>
                    </div>
                </div>
                <div className="headerSearchDiv">
                    <img src="../assets/Search.svg" alt="Paieska"></img>
                </div>
            </div>
            {
                open ? 
                    (
                    <>
                    <div className="dropdownLine" ></div>
                    <div className="dropdownMenu dropdownDesktop" ref={desktopMenuRef}>
                        <ul>
                            {MOCK_PRODUCTS.map((product, index) => 
                                <li key={index} className="dropdownItem">
                                    <div className={activeProd === product.id ? "dropdownLink active" : "dropdownLink"} onClick={() =>handleLinkClick(product.id)}>{product.name}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="dropdownMobileBackground" ref={mobileMenuRef} ></div>
                    <div className="dropdownMenu dropdownMobile" ref={mobileMenuBackgroundRef}>
                        <img src="../assets/Iksas.svg" alt="X" className="iksas" onClick={() => {handleOpen()}}></img>
                        <ul>
                            {MOCK_PRODUCTS.map((product, index) => 
                                <li key={index} className="dropdownItem">
                                    <div className={activeProd === product.id ? "dropdownLink active" : "dropdownLink"} onClick={() => handleLinkClick(product.id)}>{product.name}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                    </>)
                : null
            }
        </nav>
    );
}

export default Header