export class Product{
    constructor(initializer) {
        this.id = undefined;
        this.name = "";
        this.products = [];
        this.allProducts = [];
        this.tech = [];
        this.imgUrl = "";
        this.description = [];
        this.bgColor = undefined;
        this.iconLocation = [];
        this.imgNum = "";

        if (!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.products) this.products = initializer.products;
        if (initializer.allProducts) this.allProducts = initializer.allProducts;
        if (initializer.tech) this.tech = initializer.tech;
        if (initializer.imgUrl) this.imgUrl = initializer.imgUrl;
        if (initializer.description) this.description = initializer.description;
        if (initializer.bgColor) this.bgColor = initializer.bgColor;
        if (initializer.iconLocation) this.iconLocation = initializer.iconLocation;
        if (initializer.imgNum) this.imgNum = initializer.imgNum
    }
}