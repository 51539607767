import Map from "./map";

function Footer() {
    return <div className="footerDiv">
        <div>
            <div className="imonesInfo">
                <img src="../assets/LogoFooter.svg" alt="logo"></img>
                <h3>UAB Ukmergės staklės</h3>
                <div className="footerTextDiv">Linų g. 81, LT-20174 Ukmergė, Lietuva<br/><br/></div>

                <div className="footerTextDiv">
                    Įmonės kodas 183140725
                    <br/>PVM kodas LT831407219
                </div>

                <div className="border"></div>

                <div className="footerKontaktai">
                    +370 614 83 604
                    <br/>info@ukmergesstakles.lt
                </div>
            </div>
            <a rel="noreferrer noopener" target="_blank" href='https://maps.app.goo.gl/2gdFi2dY5fbngc3JA'>
                <Map></Map>
            </a>
            {/* <div className='location'><img src='../assets/Zemelapis.png' className='locationImage' alt='map'></img></div> */}
        </div>
    </div>
}

export default Footer;