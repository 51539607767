import React from "react";
import { useNavigate } from "react-router-dom";

function ProductCard(props){
    let {product} = props
    const navigate = useNavigate()
    const linkDest = "/produktas/"
    return(
        <div className="productCard" key={product.id} style={{backgroundColor: `${product.bgColor}`, color: `${product.bgColor === "#EDEDED" ? "#001A3D" : "white"}`}} onClick={() => navigate(linkDest+product.id)}>
            <h3>{product.name}</h3>
            <div className="categories">
                {product.products.map((item, index) => {
                    return <div key={index}>{item}</div>
                })}
            </div>
            <img className="cardImg" alt={product.name} src={"assets/KaruselesIkonos/"+product.id+".svg"} style={{top: product.iconLocation[0], left: product.iconLocation[1]}}></img>
            <img className="cardArrow" alt="į produktą" src= {`${product.bgColor === "#EDEDED" ? "assets/Arrow.svg" : "assets/ArrowWhite.svg"}`}></img>
        </div>
    );
}

export default ProductCard