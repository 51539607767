import React, { useState } from "react";
import ProductCard from "./ProductCard";
import { Product } from "./Product";
import { MOCK_PRODUCTS } from "./MockProducts";

function ProductList(props){
    let {products} = props
    const [open, setOpen] = useState(false)
    const [displayedProducts, setDisplayedProducts] = useState(4)
    const handleOpen = () => {
        setOpen(!open)
    }
    const handleMoreProducts = () => {
        setDisplayedProducts(displayedProducts + 4)
    }
    let items    
    items = products.map(product => (
        <ProductCard key={product.id} product={product}></ProductCard>
    ))
    return <>
        <div className="productList">
        {items}
        <div className="galimybes">
            <h3>Mūsų galimybės</h3>
            <p> Tekinimas (CNC su frezavimo galimybe), CNC drožimas, CNC krumplių frezavimas, 
                koordinatinis tekinimas, gręžimas, šlifavimas, lazerinis pjovimas, lankstymas, 
                suvirinimas, terminis apdirbimas (cementacija, grūdinimas, paviršinis grūdinimas 
                aukšto dažnio elektros srove) ir kiti metalo apdirbimo darbai. <br/><br/>

                <div className={open ? "galimybesPlaciau galimybesOpen" : "galimybesPlaciau"}>Traktorių, ekskavatorių, miško technikos, krautuvų ir kitos technikos tiltų, krumpliaratinių pavarų, 
                reduktorių, judančių mazgų defektavimas, restauracija ir remontas. <br/><br/>
                
                Gamyba atliekama iš anglinių, legiruotųjų, mažaanglių cementuojamų plienų, nerūdijančių plienų, spalvotųjų metalų,
                plastiko, tekstolito ir kitų medžiagų. Atliekame serijinę ir vienetinę gamybą pagal pateiktus projektus ar detalės 
                pavyzdį. Gamybą atliekame pagal visus technologinius reikalavimus.</div></p>
            <div className="buttonDiv" onClick={() => handleOpen()}>Plačiau
                {open ? 
                    <i className="arrow up" style={{top: "2px"}}></i> : 
                    <i className="arrow down"></i>
                }
            </div>
        </div>
        </div>

        <div className="productList productListMobile">
        {items.slice(0, displayedProducts)}
        {displayedProducts < 12 && <div className="download" onClick={() => handleMoreProducts()}>Daugiau gaminių <i className="arrow down" style={{borderBottom: "solid white", borderRight: "solid white"}}></i></div>}
        <div className="galimybes">
            <h3>Mūsų galimybės</h3>
            <p> Tekinimas (CNC su frezavimo galimybe), CNC drožimas, CNC krumplių frezavimas, 
                koordinatinis tekinimas, gręžimas, šlifavimas, lazerinis pjovimas, lankstymas, 
                suvirinimas, terminis apdirbimas (cementacija, grūdinimas, paviršinis grūdinimas 
                aukšto dažnio elektros srove) ir kiti metalo apdirbimo darbai. <br/><br/>

                <div className={open ? "galimybesPlaciau galimybesOpen" : "galimybesPlaciau"}>Traktorių, ekskavatorių, miško technikos, krautuvų ir kitos technikos tiltų, krumpliaratinių pavarų, 
                reduktorių, judančių mazgų defektavimas, restauracija ir remontas. <br/><br/>
                
                Gamyba atliekama iš anglinių, legiruotųjų, mažaanglių cementuojamų plienų, nerūdijančių plienų, spalvotųjų metalų,
                plastiko, tekstolito ir kitų medžiagų. Atliekame serijinę ir vienetinę gamybą pagal pateiktus projektus ar detalės 
                pavyzdį. Gamybą atliekame pagal visus technologinius reikalavimus.</div></p>
            <div className="buttonDiv" onClick={() => handleOpen()}>Plačiau
                {open ? 
                    <i className="arrow up" style={{top: "2px"}}></i> : 
                    <i className="arrow down"></i>
                }
            </div>
        </div>
        </div>
    </>
}

export default ProductList