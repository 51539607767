import React from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./Home";
import ProductDetails from "./products/ProductDetails";
import { MOCK_PRODUCTS } from "./products/MockProducts";
import ApieMus from "./ApieMus";
import ESParama from "./ESParama";

function CustomRouter(){
    const products = MOCK_PRODUCTS;
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/produktas/:id" element={<ProductDetails products={products}></ProductDetails>}></Route>
                <Route path="/apiemus" element={<ApieMus></ApieMus>}></Route>
                {<Route path="/es" element={<ESParama></ESParama>}></Route>}
            </Routes>
        </Router>
    );
}

export default CustomRouter