import React from "react";
import Header from "./header/header";
import Footer from "./footer/footer";
import ProductCard from "./products/ProductCard";
import ProductList from "./products/ProductList";
import { MOCK_PRODUCTS } from "./products/MockProducts";
import Susisiekimas from "./Susisiekimas";

function Home(){
    return(
        <>
        <Header activeNav={1}></Header>
        <section className="mainPageProducts">
            <div className="mechaniniaiKomponentai">
                <div className="komponentuDiv">
                    <div className="komponentai">Mechaniniai komponentai</div>
                    <div className="paskirtis">
                        <div>Pramonės įrenginiams</div>
                        <div>Sunkiajai technikai</div>
                        <div>Žemės<br/> ūkiui</div>
                    </div>
                </div>
                <div className="komponentuImgDiv">
                    <img src="assets/Komponentai.png" alt="komponentu nuotrauka"></img>
                </div>
            </div>
            <div className="mobileSearchDiv">
                <img src="../assets/Search.svg" alt="Paieska"></img>
            </div>
            <ProductList products={MOCK_PRODUCTS}/>
            <h3 className="mobileOperacijosH">Operacijos</h3>
            <div className="operacijos">
                <h3>Operacijos</h3>
                <img src="assets/Operacijos.svg" alt="operacijos"></img>
            </div>
            <div className="katalogasMobile">
                <img src="../assets/Excl.svg" alt="!" className="excl"></img>
                <div className="infoText">Atliekame serijinę ir vienetinę gamybą pagal pateiktus projektus ar detalės pavyzdį.<br/>Gamybą atliekame pagal visus technologinius reikalavimus.</div>
                <a className="download" href="../assets/Katalogas lt.pdf" download>Atsisiųsti paslaugų katalogą</a>
            </div>
            <div className="susisiekimasMobile">
                <h3>Susisiekime</h3>
                <div className="kont">
                <div className="kont2">
                    <div>+370 687 13 425</div>
                    <div>+370 614 83 604</div>
                </div>
                <div className="kont3">info@ukmergesstakles.lt</div>
                </div>
            </div>
        </section>
        <Susisiekimas></Susisiekimas>
        <Footer></Footer>
        </>
    );
}

export default Home