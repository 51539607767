import { useNavigate, useParams } from "react-router-dom";
import Header from "../header/header";
import { Product } from "./Product";
import Footer from "../footer/footer";
import Susisiekimas from "../Susisiekimas";
import { useEffect, useRef, useState } from "react";

function ProductDetails(props){
    const {products} = props;
    const [bigPicture, setBigPicture] = useState(0);
    const [imgIndex, setImgIndex] = useState(1);
    const [bigImgIndex, setBigImgIndex] = useState(1);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1300);

    const handleArrowClick = (x) => {
        if(imgIndex > 1 || x > 0){
            if (imgIndex+4+x <= product.imgNum){
                setImgIndex(imgIndex+x)
            }else if (imgIndex+3+x <= product.imgNum && window.innerWidth <= 1300){
                setImgIndex(imgIndex+x)
            }
        }
    }

    const handleBigImgArrowClick = (x) => {

        //left arrow wrap to end
        if(bigImgIndex === 1 && x < 0){
            setBigImgIndex(product.imgNum)
            if(window.innerWidth > 1300){
                setImgIndex(product.imgNum - 4 > 0 ? product.imgNum - 4 : 1)
            }else{
                setImgIndex(product.imgNum - 3 > 0 ? product.imgNum - 3 : 1)
            }
        //right arrow wrap to start
        }else if(bigImgIndex === product.imgNum && x > 0){
            setBigImgIndex(1)
            setImgIndex(1)
        }else{
            //if not going too far left
            if(imgIndex > 1 || x > 0){
                //if not going too far right
                if (imgIndex+4+x <= product.imgNum){
                    setImgIndex(imgIndex+x)
                //if not going too far right (mobile - 1 fewer img)
                }else if (imgIndex+3+x <= product.imgNum && window.innerWidth <= 1300){
                    setImgIndex(imgIndex+x)
                }
            }
            if(bigImgIndex > 1 || x > 0){
                if(bigImgIndex + x <= product.imgNum){
                    setBigImgIndex(bigImgIndex+x)
                }else if (bigImgIndex+3+x <= product.imgNum && window.innerWidth <= 1300){
                    setBigImgIndex(bigImgIndex+x)
                }
            }
        }
    }

    const handleNavigate = (i) => {
        setImgIndex(1)
        setBigImgIndex(1)
        i === 1 ? navigate(linkDest+nextProduct.id) : navigate(linkDest+prevProduct.id)
    }

    const isFirstRender = useRef(true)
    const params = useParams();
    const navigate = useNavigate();
    const product = new Product(products[params.id-1])
    const prevProduct = product.id !== 1 ? products[product.id-2] : products[products.length - 1]
    const nextProduct = product.id !== products.length ? products[product.id] : products[0]
    const linkDest = "/produktas/"
    var keyVar = 1

    //scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const handleResize = () => {
        if (window.innerWidth > 1300 && !isDesktop){
            if(imgIndex > 1) setImgIndex(imgIndex-1)
        }

        if (window.innerWidth <= 1300) {
            setIsDesktop(false)
        }else{
            setIsDesktop(true)
        }
    }

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false
            return
        }
        window.scrollTo(0, 240)
    }, [bigPicture])

    function handleImageClick(i){
        setBigImgIndex(i)
        setBigPicture(1)
    }

    return (
        <>
            <Header activeNav={1} activeProd={product.id}></Header>
            <section className="productPage">
                    <div className="mobileSearchDiv">
                        <img src="../assets/Search.svg" alt="Paieska"></img>
                    </div>
                <div className="pavadinimas">
                    <h3>{product.name}</h3>

                    {bigPicture === 0 && 
                    <>
                    <div className="karusele">
                        <div onClick={() => handleNavigate(-1)}><img src="../assets/ArrowLeft.svg" alt="<" className="arrowLeftImg"></img>{prevProduct.name}</div>
                        <div className="borderDiv"></div>
                        <div onClick={() => handleNavigate(1)}>{nextProduct.name}<img src="../assets/ArrowRight.svg" alt=">" className="arrowRightImg"></img></div>
                    </div>
                    <div className="karusele karuseleMobile">
                        <div onClick={() => handleNavigate(-1)}><img src="../assets/ArrowLeft.svg" alt="<" className="arrowLeftImg"></img></div>
                        <div className="borderDiv"></div>
                        <div onClick={() => handleNavigate(1)}><img src="../assets/ArrowRight.svg" alt=">" className="arrowRightImg"></img></div>
                    </div>
                    </>
                    }

                    {bigPicture === 1 && 
                    <div className="karusele">
                        <img src="../assets/Iksas.svg" alt="X" className="iksas" onClick={() => {setBigPicture(0)}}></img>
                    </div>
                    }

                </div>

                {bigPicture === 0 ? 

                <>
                <div className="gaminiai">
                    <h5>Gaminiai</h5>
                    <div>{product.allProducts.map((prod, index) => {return <div key={product.id + index*1000}>{prod}</div>})}</div>
                </div>
                <div className="tech">
                    <h5>Techninės galimybės</h5>
                    <div>{product.tech.map((prod, index) => {keyVar=keyVar+index; return <div key={keyVar}>{prod}</div>})}</div>
                </div>
                <div className="lentele">
                    <div className="lenteleIkonaDiv">
                        <img src={"../assets/GaminiuIkonos/"+product.id+".svg"} alt="ikona"></img>
                    </div>
                    <img src={"../assets/GaminiuLenteles/"+product.id+".svg"} alt="lentelė" className="gaminioLentele"></img>
                </div>
                <div className="katalogasMobile">
                    <img src="../assets/Excl.svg" alt="!" className="excl"></img>
                    <div className="infoText">Atliekame serijinę ir vienetinę gamybą pagal pateiktus projektus ar detalės pavyzdį.<br/>Gamybą atliekame pagal visus technologinius reikalavimus.</div>
                    <a className="download" href="../assets/Katalogas lt.pdf" download>Atsisiųsti paslaugų katalogą</a>
                </div>
                <div className="pvz">
                    <div className="pvzTop">
                        <h5>Gaminių pavyzdžiai</h5>
                        <div className="nuotraukos">
                            <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+imgIndex+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex))}></img>
                            <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+1)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+1))}></img>
                            {product.imgNum >= 3 ? <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+2)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+2))}></img> : <div className="gaminioNuotrauka nuotraukosPlaceholder"></div>}
                            {product.imgNum >= 4 ? <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+3)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+3))}></img> : <div className="gaminioNuotrauka nuotraukosPlaceholder"></div>}
                            {product.imgNum >= 5 ? <img className="gaminioNuotrauka nuotrauka5" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+4)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+4))}></img> : <div className="gaminioNuotrauka nuotraukosPlaceholder nuotrauka5"></div>}
                        </div>
                    </div>
                    <div className="ikonos">
                        <img src="../assets/Excl.svg" alt="!" className="excl"></img>
                        {(product.imgNum > 5 || (product.imgNum >= 5 && window.innerWidth <= 1300)) && <img src="../assets/ArrowLeft.svg" alt="<" className="arrowLeftImg" onClick={() => handleArrowClick(-1)}></img>}
                        {(product.imgNum > 5 || (product.imgNum >= 5 && window.innerWidth <= 1300)) && <img src="../assets/ArrowRight.svg" alt=">" className="arrowRightImg" onClick={() => handleArrowClick(1)}></img>}
                    </div>
                    <div className="papildomaInfo">
                        <div className="infoText">Atliekame serijinę ir vienetinę gamybą pagal pateiktus projektus ar detalės pavyzdį. Gamybą atliekame pagal visus technologinius reikalavimus.</div>
                        <div className="borderDiv"></div>
                        <a className="download" href="../assets/Katalogas lt.pdf" download>Atsisiųsti paslaugų katalogą</a>
                    </div>
                </div>
                </>

                :

                <>
                <div>
                    <img className="bigImage" src={"../assets/GaminiuNuotraukos/"+product.name+"/"+bigImgIndex+".jpg"} alt="Nuotrauka"></img>
                </div>
                <div className="bigImageArrowDiv">
                    <img src="../assets/ArrowLeft.svg" alt="<" className="arrowLeftImg" onClick={() => handleBigImgArrowClick(-1)}></img>
                    <img src="../assets/ArrowRight.svg" alt=">" className="arrowRightImg" onClick={() => handleBigImgArrowClick(1)}></img>
                </div>
                <div className={bigPicture === 0 ? "nuotraukos" : "nuotraukos bigKarusele"}>
                <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+imgIndex+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex))}></img>
                <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+1)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+1))}></img>
                {product.imgNum >= 3 ? <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+2)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+2))}></img> : <div className="gaminioNuotrauka nuotraukosPlaceholder"></div>}
                {product.imgNum >= 4 ? <img className="gaminioNuotrauka" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+3)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+3))}></img> : <div className="gaminioNuotrauka nuotraukosPlaceholder"></div>}
                {product.imgNum >= 5 ? <img className="gaminioNuotrauka nuotrauka5" src={"../assets/NuotraukuIkonos/"+product.name+"/"+(imgIndex+4)+".jpg"} alt="iliustracija" onClick={() => (handleImageClick(imgIndex+4))}></img> : <div className="gaminioNuotrauka nuotraukosPlaceholder nuotrauka5"></div>}
                </div>
                <div className="ikonos bigImageIkonos">
                    {(product.imgNum > 5 || (product.imgNum >= 5 && window.innerWidth <= 1300)) && <img src="../assets/ArrowLeft.svg" alt="<" className="arrowLeftImg" onClick={() => handleArrowClick(-1)}></img>}
                    {(product.imgNum > 5 || (product.imgNum >= 5 && window.innerWidth <= 1300)) && <img src="../assets/ArrowRight.svg" alt=">" className="arrowRightImg" onClick={() => handleArrowClick(1)}></img>}
                </div>
                </>

                }
                <div className="susisiekimasMobile">
                    <h3>Susisiekime</h3>
                    <div className="kont">
                    <div className="kont2">+370 614 83 604</div>
                    <div className="kont3">info@ukmergesstakles.lt</div>
                </div>
            </div>
            </section>
            
            <Susisiekimas></Susisiekimas>
            <Footer></Footer>
        </>
    );
}

export default ProductDetails