import logo from './logo.svg';
import './App.css';
import Home from './Home';
import CustomRouter from './CustomRouter'

function App() {
  return (
    <div className="App">
      <CustomRouter></CustomRouter>
    </div>
  );
}

export default App;
