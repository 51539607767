import Header from "./header/header";
import Footer from "./footer/footer";
import Susisiekimas from "./Susisiekimas";

function ApieMus(){
    return(
        <>
            <Header activeNav={2}></Header>
            <div className="apieMus">
                <div className="apieMusTop">
                    <div>
                        <h3>Apie mus</h3>
                        <div className="textDiv">UAB “Ukmergės staklės” veiklą vykdo nuo 2000 metų, buvusios valstybinės remonto mechaninės gamyklos bazėje, kartu perėmusi įmonės “Krumpliaratis” įrengimus, gamybines patalpas ir aukštos kvalifikacijos specialistus. Nuo 2005 metų įmonė veikia buvusios įmonės “Linų fabrikas” patalpose, kurios sudaro 3100 kvadratinių metrų.</div>
                    </div>
                    <img src="assets/Logo.svg" alt="logo"></img>
                </div>
                <div className="apieMusBot">
                    <h3>Atliekami darbai</h3>
                    <div>
                        <div className="textDiv">   Esame vienas didžiausių kokybiškų įvairios paskirties mechaninių komponentų gamintojų Lietuvoje. 
                                Specializuojamės velenų, krumpliaračių, skriemulių, movų, grandinių, dantračių, krumpliastiebių, sliekų, sliekračių, peilių, kitų sudėtingų detalių ir komponentų gamyboje, remonto ir restauracijos paslaugose. Taip pat atliekame gaminių terminį apdirbimą.

                                <br/><br/>Atliekame tekinimo (CNC su frezavimo galimybe), CNC drožimo, CNC krumplių frezavimo, koordinatinio tekinimo, gręžimo, šlifavimo, štampavimo, lankstymo, suvirinimo, terminio apdirbimo (cementacijos, grūdinimo, paviršinio grūdinimo aukšto dažnio elektros srove) ir kitus metalo apdirbimo darbus.</div>
                        <div className="textDiv">   Gamybą atliekame iš anglinių, legiruotųjų, mažaanglių cementuojamų plienų, nerūdijančių plienų, spalvotųjų metalų, plastiko, tekstolito ir kitų medžiagų.

                                <br/><br/>Atliekame serijinę ir vienetinę gamybą pagal pateiktus projektus ar detalės pavyzdį. Gamybą atliekame pagal visus technologinius reikalavimus.

                                <br/><br/>Atliekame traktorių, ekskavatorių, miško technikos, krautuvų ir kitos technikos tiltų, krumpliaratinių pavarų, reduktorių, judančių mazgų defektavimą, restauraciją ir remontą.</div>
                    </div>
                </div>
            </div>
            <div className="operacijos apieMusOp">
                <h3>Operacijos</h3>
                <img src="assets/Operacijos.svg" alt="operacijos"></img>
            </div>
            <div id="kolektyvo-section" className="kolektyvas">
                <h3>Mūsų kolektyvas</h3>
                <div>
                    <div className="asmuo">
                        <img src="/assets/Profilis.svg" alt="nuotrauka"></img>
                        <div>
                            <h5>Darius Brasiūnas</h5>
                            <div className="role">Direktorius</div>
                            <div>+370 683 33 388</div>
                            <div>darius.brasiunas@ukmergesstakles.lt</div>
                        </div>
                    </div>
                    <div className="asmuo">
                        <img src="/assets/Profilis.svg" alt="nuotrauka"></img>
                        <div>
                            <h5>Darius Micius</h5>
                            <div className="role">Vadybininkas</div>
                            <div>+370 687 13 425</div>
                            <div>darius.micius@ukmergesstakles.lt</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="asmuo">
                        <img src="/assets/Profilis.svg" alt="nuotrauka"></img>
                        <div>
                            <h5>Karolis Inčiūra</h5>
                            <div className="role">Vadybininkas</div>
                            <div>+370 614 83 604</div>
                            <div>karolis.inciura@ukmergesstakles.lt</div>
                        </div>
                    </div>
                    <div className="asmuo">
                        <img src="/assets/Profilis.svg" alt="nuotrauka"></img>
                        <div>
                            <h5>Juozas Berioza</h5>
                            <div className="role">Vyr. meistras</div>
                            <div>gamyba@ukmergesstakles.lt</div>
                        </div>
                    </div>
                </div>
            </div>
            <Susisiekimas></Susisiekimas>
            <Footer></Footer>
        </>
    )
}

export default ApieMus