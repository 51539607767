import { Product } from "./Product";

export const MOCK_PRODUCTS = [
    new Product({
        id: 1,
        name: "Velenai",
        products: [
            "Velenai", "Velenai-krumpliaračiai", "Pusašiai", "Kardaniniai velenai"
        ],
        allProducts: [
            "Velenai", "Velenai-krumpliaračiai", "Pusašiai", "Kardaniniai velenai"
        ],
        tech: [
            "Ilgis iki 2500 mm su evolventinėmis arba stačiakampėmis išdrožomis",
            "Iki Ø120 mm, ilgis iki 1000 mm",
            "Iki Ø80 mm, ilgis nuo 1000 mm ",
            "Velenai-krumpliaračiai iki Ø200 mm, ilgis iki 1000 mm",
            "Velenų cilindrinių paviršių šlifavimo ilgis iki 1000 mm",
            <br/>,
            "Tekinimas ",
            "Ø10–80 mm, ilgis iki 3000 mm",
            "Ø80–200 mm, ilgis iki 2000 mm",
            "Ø200–700 mm, ilgis iki 500 mm ",
            "Ø700– 900 mm, ilgis iki 300 mm",
            <br/>,
            "Šlifavimas ",
            "Iki Ø250 mm, ilgis iki 1000 mm, svoris iki 100 kg",
            "Krumplinių išdrožų frezavimas ant velenų iki Ø250 mm, ilgis iki 1100 mm"
        ],
        bgColor: "#001A3D",
        iconLocation: [0, 223],
        imgNum: 12,
    }),
    new Product({
        id: 2,
        name: "Krumpliaračiai",
        products: [
            "Krumpliaračiai", "Krumpliaratinės pavaros", "Reduktoriai", "Blokiniai krumpliaračiai"
        ],
        allProducts: [
            "Krumpliaračiai", "Krumpliaratinės pavaros", "Reduktoriai", "Blokiniai krumpliaračiai"
        ],
        tech: [
            "Cilindriniai krumpliaračiai su tiesiais išoriniais krumpliais iki Ø1000 mm, modulis m0,5–14",
            "Kūginiai krumpliaračiai su tiesiais krumpliais Ø20-Ø500 mm, modulis m1-10",
            "Cilindriniai įstrižakrumpliai krumpliaračiai iki Ø1000 mm, modulis m1-14",
            "Sliekračiai krumpliaračiai Ø15–1000 mm, modulis m0,5–14",
            <br/>,
            "Tekinimas",
            "Ø10–80 mm, ilgis iki 3000 mm",
            "Ø80–200 mm, ilgis iki 2000 mm",
            "Ø200–700 mm, ilgis iki 500 mm",
            "Ø700– 900 mm, ilgis iki 300 mm",
            <br/>,
            "Šlifavimas iki Ø250 mm, ilgis iki 1000 mm, svoris iki 100 kg",
            "Krumplinių išdrožų frezavimas ant velenų iki Ø250 mm, ilgis iki 1100 mm",
            "Pleištavietės drožimas iki Ø700 mm, plotis nuo 3 mm, ilgis priklauso nuo pločio"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 310],
        imgNum: 6,
    }),
    new Product({
        id: 3,
        name: "Skriemuliai",
        products: [
            "Trapecinio profilio skriemuliai", "Vienaeiliai skriemuliai", "Daugiaeiliai skriemuliai"
        ],
        allProducts: [
            "Trapecinio profilio skriemuliai", "Vienaeiliai skriemuliai", "Daugiaeiliai skriemuliai"
        ],
        tech: [
            "Tekinimas",
            "Ø10-80 mm, ilgis iki 3000 mm",
            "Ø80-200 mm, ilgis iki 2000 mm",
            "Ø200-700 mm, ilgis iki 500 mm ",
            "Ø700- 900 mm, ilgis iki 300 mm",
            <br/>,
            "Pleištavietės drožimas iki Ø700 mm, plotis nuo 3 mm, ilgis priklauso nuo pločio",
            <br/>,
            "Vidinių skylių šlifavimas nuo Ø350 mm, ilgis iki 500 mm, svoris iki 100 kg, skylė nuo Ø10 mm"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 292],
        imgNum: 2,
    }),
    new Product({
        id: 4,
        name: "Movos",
        products: [
            "Sujungimo movos", "Perėjimai velenams", "Flanšai", "Jungės", "..."
        ],
        allProducts: [
            "Sujungimo movos",
            "Perėjimai-adapteriai darbiniams velenams 8x6, 6x8, 8x12, 21x8, 6x21, 21x6 mm",
            "Flanšai",
            "Jungės",
            "Kumštelinės movos",
            "Movos su evolventinėmis ir stačiakampėmis išdrožomis",
            "Kūginės movos",
            "Užveržiamos movos"
        ],
        tech: [
            "Tekinimas",
            "Ø10-80 mm, ilgis iki 3000 mm",
            "Ø80-200 mm, ilgis iki 2000 mm",
            "Ø200-700 mm, ilgis iki 500 mm ",
            "Ø700-900 mm, ilgis iki 300 mm",
            <br/>,
            "Pleištavietės drožimas iki Ø700 mm, plotis nuo 3 mm, ilgis priklauso nuo pločio",
            "Evolventinių išdrožų drožimas",
            <br/>,
            "Vidinių skylių šlifavimas nuo Ø350 mm, ilgis iki 500 mm, svoris iki 100 kg, skylė nuo Ø10 mm"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 266],
        imgNum: 4,
    }),
    new Product({
        id: 5,
        name: "Grandinės",
        products: [
            "Įvairaus žingsnio grandinės", "Ekskavatorių grandinės", "Specifinės grandinės", "Jungtys", "..."
        ],
        allProducts: [
            "Įvairaus žingsnio standartinės ir nestandartinės grandinės žemės ūkio technikai",
            "Vienakaušių ir daugiakaušių ekskavatorių grandinės",
            "Specifinės tranšėjų kasimo grandinės",
            "Nestandartiniai grandinių sujungimai",
            "Jungtys daugiaeilėms grandinėms"
        ],
        tech: [
            "Colinių grandinių žingsnis t50–300 mm",
            "Konvejerinių grandinių žingsnis t50–300 mm"
        ],
        bgColor: "#001A3D",
        iconLocation: [0, 327],
        imgNum: 5,
    }),
    new Product({
        id: 6,
        name: "Žvaigždutės",
        products: [
            "Vienaeilės ir daugiaeilės žvaigždutės", "Varančios, įtempimo žvaigždutės", "Žvaigždutės kasimo technikai", "..."
        ],
        allProducts: [
            "Vienaeilės ir daugiaeilės žvaigždutės žemės ūkio technikai: sėjamosioms, ruloniniams presams, kombainams",
            "Varančios, įtempimo ir kitos žvaigždutės konvejeriams",
            "Žvaigždutės kasimo technikai, pramonės įrenginiams"
        ],
        tech: [
            "Grandinės su įvorėmis ir ritinėliais žingsnis 8-50,8 mm",
            "Iki Ø1000 mm, frezavimas universaliomis krumplių frezavimo staklėmis",
            "Iki Ø350 mm, frezavimas 6 ašių CNC krumplių frezavimo staklėmis",
            <br/>,
            "Colinės Vienaeilės t5–44,5 mm, iki Ø1000 mm",
            "Colinės Daugiaeilės t5–44,5 mm, iki Ø1000 mm",
            "Konvejerinės t50–250 mm, Ø – pagal situaciją"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 286],
        imgNum: 4,
    }),
    new Product({
        id: 7,
        name: "Krumpliastiebiai",
        products: [
            "Cilindriniai krumpliastiebiai", "Kvadratiniai krumpliastiebiai", "Nestandartiniai krumpliastiebiai", "Krumpliastiebinės pavaros", "..."
        ],
        allProducts: [
            "Cilindriniai krumpliastiebiai",
            "Kvadratiniai krumpliastiebiai",
            "Nestandartiniai tiesiakrumpliai, įstrižakrumpliai krumpliastiebiai",
            "Krumpliastiebinės pavaros miško ir krovimo technikai bei pramonės įrenginiams"
        ],
        tech: [
            "Modulis m4–14, ilgis iki 1750 mm, svoris iki 500 kg"
        ],
        bgColor: "#838C98",
        iconLocation: [0, 348],
        imgNum: 3,
    }),
    new Product({
        id: 8,
        name: "Sliekai ir sliekračiai",
        products: [
            "Sliekinės pavaros (reduktoriai)", "Vienpradžiai, dvipradžiai, tripradžiai,", "keturpradžiai sliekai", "Sliekračiai"
        ],
        allProducts: [
            "Sliekinės pavaros (reduktoriai)",
            "Vienpradžiai ir daugiapradžiai sliekai",
            "Sliekračiai"
        ],
        tech: [
            "Vienpradžiai, dvipradžiai, tripradžiai, keturpradžiai sliekai, modulis m1–10",
            "Sliekračiai iki Ø1000 mm, modulis m1–10",
            <br/>,
            "Vienpradžiai sliekai, modulis m3–14",
            "Daugiapradžiai sliekai, modulis m3–14",
            "Sliekračiai atskira stebule, modulis m3–14",
            "Sliekračiai, modulis m3–14"
        ],
        bgColor: "#EDEDED",
        iconLocation: [50, 280],
        imgNum: 3,
    }),
    new Product({
        id: 9,
        name: "Peiliai",
        products: [
            "Medienos smulkintuvų peiliai", "Rotacinių smulkintuvų peiliai", "Granuliatorių plaktukai-peiliai"
        ],
        allProducts: [
            "Medienos, šakų, biomasės smulkintuvų peiliai",
            "Rotacinių žoliapjovių–smulkintuvų peiliai",
            "Granuliatorių smulkintuvų plaktukai–peiliai"
        ],
        tech: [
            "Peilių terminis apdirbimas iki HRC 63",
            "Šlifavimas ir galandimas ilgis iki 1000 mm",
            "Plienas parenkamas pagal techninius reikalavimus"
        ],
        bgColor: "#001A3D",
        iconLocation: [50, 264],
        imgNum: 4,
    }),
    new Product({
        id: 10,
        name: "Vienetiniai gaminiai",
        products: [],
        allProducts: [
            "Tekinimas (CNC su frezavimo galimybe), CNC drožimas, CNC krumplių frezavimas, koordinatinis tekinimas, gręžimas, šlifavimas, lazerinis pjovimas, lankstymas, suvirinimas, terminis apdirbimas (cementacija, grūdinimas, paviršinis grūdinimas aukšto dažnio elektros srove) ir kiti metalo apdirbimo darbai.",
            "Traktorių, ekskavatorių, miško technikos, krautuvų ir kitos technikos tiltų, krumpliaratinių pavarų, reduktorių, judančių mazgų defektavimas, restauracija ir remontas."
        ],
        tech: [
            "Gamyba atliekama iš anglinių, legiruotųjų, mažaanglių cementuojamų plienų, nerūdijančių plienų, spalvotųjų metalų, plastiko, tekstolito ir kitų medžiagų."
        ],
        bgColor: "#EC4600",
        iconLocation: [50, 264],
        imgNum: 23,
    }),
]